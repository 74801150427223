/* Reset CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Global Styles */
body {
  font-family: "Montserrat", sans-serif;
  color: #333333;
  background-color: #f9f9f9;
}

/* Styles for MarkDown rendering */
ul {
  list-style-type: disc; /* Тип маркера списка */
  margin-bottom: 10px; /* Отступ снизу */
  margin-left: 25px;
  font-weight: normal;
}

li {
  margin: 10px 0;
  line-height: 1.4;
}

h2, h3 {
  font-size: 24px; /* Размер шрифта */
  font-weight: 600; /* Полужирный шрифт */
  margin-top: 15px; /* Отступ сверху */
  margin-bottom: 5px; /* Отступ снизу */
}

/* Additional Styles */
p {
  margin-bottom: 10px;
  line-height: 1.4; /* Устанавливает высоту строки */
  white-space: pre-line;
  font-weight: normal;
}

.content { /* Can be replaced to .container */
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 0px;
}

/* Title styles */
.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  max-height: 150px;
  max-width: 50%;
}

.title {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  margin-bottom: 5px;
}

.undertitle {
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  text-align: center;
  margin-bottom: 20px;
}

.subtitle {
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  margin-top: 20px;
  margin-bottom: 10px;
}

.divider {
  border-bottom: 1px solid #ccc;
  margin: 20px 0;
}

.textarea {
  width: 70%;
  padding: 10px;
  border: 2px solid black;
  border-radius: 5px;
  resize: none;
  overflow-y: hidden;
  min-height: 100px;
  font-family: "Montserrat", sans-serif;
}

.submit-button {
  display: block;
  width: 100%;
  padding: 10px 20px;
  background-color: #f9f9f9;
  color: black;
  border: 2px solid black;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "Montserrat", sans-serif;
  font-weight: 550;
}

.submit-button:hover {
  /* background-color: #47b1f8; */
  background-color: black;
  color: white;
}

/* Rating Styles */
.rating {
  margin-bottom: 10px;
}

.rating-low {
  color: red;
}

.rating-medium {
  color: orange;
}

.rating-high {
  color: green;
}

/* Footer Styles */
.footer {
  text-align: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ccc;
}

.footer p {
  margin-bottom: 10px;
}

/* Dividers Styles */
hr {
  border-top: 1px solid #ccc;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Header Styles */
.header { /* Can be replaced to .container */
  margin: 0 auto;
  padding-top: 20px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}

/* File Upload Styles */
.file-upload-label {
  width: 100%;
  height: 100px;
  display: inline-block;
  padding: 40px 20px;
  background-color: #f9f9f9;
  color: black;
  border: 2px solid black;
  border-radius: 5px;
  /* background-color: #3498db;
  color: white;
  border-radius: 5px; */
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "Montserrat", sans-serif;
  margin-left: 10%;
  text-align: center;
}

.file-upload-label:hover {
  /* background-color: #47b1f8; */
  background-color: black;
  color: white;
}

#file-upload {
  display: none;
}

/* Additional Styles for Photo Preview */
.photo-preview {
  width: 100%;
  height: fit-content;
  border-radius: 10px;
  margin-left: 10%;
  margin-top: 5%;
  border: 1px solid #ccc;

}

.inputdiv {
  display:flex;
  margin-bottom: 20px;
  margin-top: 20px;
}
.photoinputdiv {
  display: grid;
  width: 27%;
}

/* Color styles */
/* Main Red: #FF0000 */
/* Add Red: #FF4135 */
/* Main Blue: #3498db */
/* Add Blue: #47b1f8 */